:root {
	--sc-white: #fff;
	--sc-light-grey: #eee;
	--infinite-scroll-time: 60s;
	--bg-dark: #212529;
	--tsd-glow: #fff;
	--tsd-green-dark: #283f39;
	--tsd-green-mid: #487267;
	--tsd-green-light: #639e8f;
	--width-xl: 1280px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--bg-dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

body {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
		'Lucida Sans', Arial, sans-serif;
	background-color: var(--bg-dark);
	color: #fff;
}

.btn {
	color: var(--bg-dark);
	background-color: var(--sc-white);
	border: none;
}
.btn:hover {
	color: var(--bg-dark);
	background-color: var(--sc-white);
	box-shadow: 0 0 8px 3px var(--sc-white);
	transition: all 0.3s ease-in-out;
}
.btn:disabled {
	color: var(--bg-dark);
	background-color: var(--sc-white);
}

.btn-outline {
	background-color: var(--bg-dark);
	color: var(--sc-white);
	border: 1px solid var(--sc-white);
}
.btn-outline:hover {
	border: 1px solid var(--sc-white);
	color: var(--sc-white);
	background-color: var(--bg-dark);
	box-shadow: 0 0 8px 3px var(--sc-white);
	transition: all 0.3s ease-in-out;
}

canvas {
	border: 2px solid black;
	border-radius: 5px;
}

#info-pane {
	width: calc(100% - 1355px);
	margin-left: 15px;
	border: 2px solid black;
	border-radius: 5px;
	padding: 10px;
}
#info-pane h3 {
	margin: 0;
	padding: 0;
}
#info-pane #info {
	clear: both;
}

#root > div:first-of-type {
	padding-top: 80px;
}

/*
	Composable classes
*/

.bold {
	font-weight: bold;
}
.bg-dark {
	background-color: var(--bg-dark);
}

.dialog-header {
	display: flex;
	justify-content: space-between;
}

.chip {
	border: none;
	background-color: var(--tsd-green-dark);
	border-radius: 16px;
	padding: 4px 1rem;
	color: #fff;
}

.icon-button {
	color: inherit;
	background: none;
	border: 0;
	transition: 0.25s;
}
.icon-button:hover {
	transform: scale(1.5);
}

.italic {
	font-style: italic;
}

.flex {
	display: flex;
}
.flex-1 {
	flex: 1;
}
.flex-col {
	flex-direction: column;
}
.flex-fluid {
	display: flex;
	flex-wrap: wrap;
}
.flex-spread {
	justify-content: space-between;
}
.flex-v-center {
	align-items: center;
}
.flex-wrap {
	flex-wrap: wrap;
}

.float-left {
	float: left;
}
.float-right {
	float: right;
}

.gap-half {
	gap: 0.5rem !important;
}
.gap-1 {
	gap: 1rem !important;
}
.gap-2 {
	gap: 2rem !important;
}

.row-gap-0 {
	row-gap: 0 !important;
}
.row-gap-half {
	row-gap: 0.5rem !important;
}

.horizontal-card {
	display: flex;
	width: fit-content;
	height: 60px;
	border: 1px solid var(--tsd-green-dark);
	border-radius: 8px;
	transition: border-color 0.3s;
}
.horizontal-card div:first-child {
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--tsd-green-dark);
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	transition: background-color 0.3s;
}
.horizontal-card div:nth-child(2) {
	display: flex;
	align-items: center;
	padding: 0.25rem 1rem;
}
.horizontal-card p,
.horizontal-card h5 {
	margin: 0;
}
.horizontal-card:hover {
	border-color: var(--tsd-green-light);
}
.horizontal-card:hover div:first-child {
	background-color: var(--tsd-green-light);
}

.image-card {
	width: 18rem;
	height: 29rem;
	background-color: #434951;
	color: #fff;
	border: 0;
	transition: all ease-in 0.25s;
}
.image-card:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 5px #fff;
}
.image-card .card-text {
	overflow: hidden;
	display: -webkit-box;
	height: 4.5rem;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}


.retail-property-card {
	width: 30rem;
	height: 15rem;
	background-color: var(--bg-dark);
	color: #fff;
	border: 1px solid #fff;
	padding: 1rem;
	border-radius: 16px;
	transition: all ease-in 0.25s;
}

.link {
	color: inherit;
}
.link:hover {
	cursor: pointer;
}
.link:visited {
	cursor: pointer;
	color: inherit;
}

.m-0 {
	margin: 0 !important;
}
.mt-1 {
	margin-top: 1rem !important;
}

.overflow-hide {
	overflow: hidden;
}

.page-tabs {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 0.5rem 2rem;
	gap: 1rem;
}
.page-tabs button {
	transition: background-color 0.3s;
}
.page-tabs button:hover {
	background-color: var(--tsd-green-light);
}
.page-tabs .active {
	background-color: var(--tsd-green-mid);
}

.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 1rem !important;
}
.p-2 {
	padding: 2rem !important;
}

.p-x-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.p-x-2 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.p-y-1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.p-y-2 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.p-t-1 {
	padding-top: 1rem;
}
.p-t-2 {
	padding-top: 2rem;
}

.p-b-1 {
	padding-bottom: 1rem;
}
.p-b-2 {
	padding-bottom: 2rem;
}

.relative {
	position: relative;
}

.underline {
	text-decoration: underline;
}

.v-stack {
	display: flex;
	flex-direction: column;
}

/*
	Specific Classes
*/

.navbar {
	position: fixed;
	width: 100%;
	box-shadow: 0 -8px 15px 5px #fff;
}

.spaceType img:hover {
	cursor: pointer;
	box-shadow: 0 0 5px 0 hsl(0, 0%, 62%);
}

.tools-drawer {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 50;
	display: grid;
	grid-template-columns: 0 48px;
	transition: all 0.5s ease-in-out;
}
.tools-drawer-show {
	grid-template-columns: 348px 48px;
	transition: all 0.5s ease-in-out;
	opacity: 0.85;
}

.tools-nav {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	gap: 24px;
	width: inherit;
	height: inherit;
}

.tools-nav div svg {
	position: relative;
	transition: transform 0.25s ease-in-out;
}

.tools-nav div svg:hover {
	transform: scale(1.3) rotate(360deg);
	transition: transform 0.25s ease-in-out;
	cursor: pointer;
}

.tools-content {
	width: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5px;
	row-gap: 24px;
}

.tools-nav-space {
	cursor: pointer;
	position: relative;
	width: 28px;
	height: 28px;
	transition: transform 0.25s ease-in-out;
}
.tools-nav-space:hover {
	transform: scale(1.3) rotate(360deg);
	transition: transform 0.25s ease-in-out;
}

.tools-nav-space span {
	width: 28px;
	height: 28px;
	left: 0;
	top: 3px;
	position: absolute;
	font-size: 10pt;
	text-align: center;
}

.flip-card {
	background-color: transparent;
	width: 100%;
	height: 100%;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: 0.6s;
	transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}
.flip-card-back {
	position: absolute;
	top: 0;
	left: 0;
	transform: rotateY(180deg);
}

.games-list {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}

.isometric-headers h2 {
	position: relative;
	transform: skewY(-1.5deg);
}
.isometric-headers h2::before {
	content: '';
	position: absolute;
	top: 0;
	left: -20px;
	width: 20px;
	height: 100%;
	background-color: grey;
	transform-origin: right;
	transform: skewY(45deg);
}
.isometric-headers h2::after {
	content: '';
	position: absolute;
	top: -20px;
	left: 0;
	width: 100%;
	height: 20px;
	background-color: lightgrey;
	transform-origin: bottom;
	transform: skewX(45deg);
}

.content-container {
	position: relative;
	max-width: var(--width-xl);
	width: 100%;
	margin: 0 auto;
	padding: 0 2rem 2rem 2rem;
}

/*
	The Stifling Dark Page
*/

.tsd-box {
	position: relative;
	display: block;
	width: min(400px, 90%);
	height: min(400px, 90%);
}
.tsd-box:hover {
	transition: 0.3s;
}
.tsd-box:hover img {
	transform: translate3d(1);
}

.tsd-box-order {
	opacity: 0;
	font-size: 24pt;
	color: white;
	text-align: center;
	position: absolute;
	top: 325px;
	left: 80px;
	width: 280px;
	height: 50px;
	transform: skewY(-6.5deg);
	transition: 0.3s;
	border-radius: 4px;
	backdrop-filter: blur(5px);
}
.tsd-box:hover .tsd-box-order {
	opacity: 1;
}

.tsd-header {
	padding: 2rem 0;
	position: relative;
	background-position-y: bottom;
	background: url('/public/images/games/the-stifling-dark/adversaries/morgonnod-section.png');
}

.tsd-glow {
	transition: 0.3s;
}
.tsd-glow:hover {
	filter: drop-shadow(0 0 10px var(--sc-white));
}

.tsd-container {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}
.tsd-container > div:last-child {
	padding-bottom: 5rem;
}

.adversary-viewer {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.adversary-viewer div .blur-block {
	position: absolute;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 12px 8px var(--tsd-green-dark) inset;
	top: 0;
	bottom: 0;
}

.investigator-details-dialog {
	position: absolute;
	left: calc(50% - 200px);
	bottom: 50px;
	padding: 1rem 2rem;
	z-index: 20;
	background-color: grey;
	height: 300px;
	max-width: 420px;

	background: rgba(255, 255, 255, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
}

.tsd-green-wrapper {
	padding: 2rem 0;
	background-color: var(--tsd-green-dark);
}

.play-now-images {
	display: flex;
	justify-content: center;
	gap: 2rem;
	flex-wrap: wrap;
}

.scroll-viewer {
	position: relative;
	display: flex;
	width: inherit;
	overflow: hidden;
	flex-wrap: nowrap;
	padding: 1rem 0;
	transition: 0.5s;
	mask-image: linear-gradient(
		90deg,
		transparent,
		#fff 20%,
		#fff 80%,
		transparent
	);
}

.scroll-viewer div {
	white-space: nowrap;
	animation: infinite-scroll var(--infinite-scroll-time) linear infinite;
	animation-delay: calc(var(--infinite-scroll-time) * -1);
}
.scroll-viewer div:nth-child(2) {
	animation: infinite-scroll-2 var(--infinite-scroll-time) linear infinite;
	animation-delay: calc(var(--infinite-scroll-time) / -2);
}
.scroll-viewer:hover div {
	animation-play-state: paused;
}

.scroll-viewer div img:hover {
	cursor: pointer;
	background-color: transparent;
}

.flashlight-viewer-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

.flashlight-viewer {
	width: 1000px;
	height: 356px;
	position: relative;
	cursor: none;
	/*mask-image: radial-gradient(
		circle at center,
		#fff,
		#fff 10%,
		#fff 80%,
		transparent 85%
	);*/
}
.flashlight-viewer::before,
.flashlight-viewer::after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url('/public/images/games/the-stifling-dark/maps/sawmill-section.png');
	background-repeat: no-repeat;
}

.flashlight-viewer::before {
	filter: brightness(0.3);
}

.flashlight-viewer::after {
	clip-path: circle(60px at var(--x, 50%) var(--y, 50%));
	z-index: 1;
}

/*
	Screen Sizes
*/

@media only screen and (max-width: 600px) {
	.page-tabs {
		flex-direction: column;
	}
	.content-container {
		padding: 0 2rem 2rem 2rem;
	}
	.games-list {
		justify-content: center;
	}
}

/*
	Animations
*/

@keyframes infinite-scroll {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
@keyframes infinite-scroll-2 {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-200%);
	}
}
